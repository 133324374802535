'use client';

import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';
import Link from 'next/link';

export default function HomeBanners({ bannerContent }) {
  const data = useMemo(() => bannerContent.json_editor?.items, [bannerContent]);

  return (
    <div className="w-full h-dvh">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 6000 }}
        pagination={true}
        modules={[Pagination, Autoplay]}
        navigation={true}
        className="w-full h-full"
      >
        {data &&
          data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="relative w-full h-full">
                <video
                  autoPlay
                  playsInline
                  muted
                  loop
                  src={item.url}
                  className="w-full h-full object-cover"
                  preload="none"
                  poster={item.posterUrl}
                />
                <div className="absolute z-10 inset-0 bg-gradient-to-r from-black/60 2xl:from-black to-transparent"></div>
                <div className="absolute z-20 top-[30%] sm:top-[35%] xl:top-1/2 fhd:top-[55%] transform text-start text-white px-4 lg:px-14 fhd:px-20 w-full md:w-[60%]">
                  {item.title && (
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-row gap-2 text-xs md:text-base tracking-[.25em] font-bold">
                        <p className="text-customColorGreen">
                          {item.title.split(' ')[0]}{' '}
                        </p>{' '}
                        <p className="text-white">
                          {item.title.split(' ').slice(1).join(' ')}{' '}
                        </p>
                      </div>
                      {index === 0 ? (
                        <h1 className="text-[28px] md:text-[30px] xl:text-[30px] 2xl:text-[35px] fhd:text-[40px] font-bold">
                          {item.description}
                        </h1>
                      ) : (
                        <h2 className="text-[28px] md:text-[30px] xl:text-[30px] 2xl:text-[35px] fhd:text-[40px] font-bold">
                          {item.description}
                        </h2>
                      )}
                      <Link
                        href={item.link}
                        className="flex flex-row gap-2 items-center group pt-10"
                        aria-label={`Link para ${item.button}`}
                      >
                        <h2 className="font-bold text-customColorWhite group-hover:text-customColorGreen200 transition duration-300">
                          {item.button}
                        </h2>
                        <div className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="8"
                            viewBox="0 0 34 8"
                            fill="none"
                          >
                            <path
                              d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM33.8536 4.35355C34.0488 4.15829 34.0488 3.84171 33.8536 3.64645L30.6716 0.464466C30.4763 0.269204 30.1597 0.269204 29.9645 0.464466C29.7692 0.659728 29.7692 0.976311 29.9645 1.17157L32.7929 4L29.9645 6.82843C29.7692 7.02369 29.7692 7.34027 29.9645 7.53553C30.1597 7.7308 30.4763 7.7308 30.6716 7.53553L33.8536 4.35355ZM1 4.5H33.5V3.5H1V4.5Z"
                              fill=""
                              className="fill-customColorWhite group-hover:fill-customColorGreen200 transition duration-300"
                            />
                          </svg>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
