import React from 'react';
import { Chakra_Petch } from 'next/font/google';

const chakra = Chakra_Petch({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700'],
});

export default function Gradient({ text, align }) {
  return (
    <h2
      className={` bg-gradient-to-r from-customColorGreen200 to-customColorGreen500 text-transparent bg-clip-text font-extrabold text-[24px] lg:text-[25px] 2xl:text-[35px] fhd:text-[40px] whitespace-nowrap`}
    >
      {text}
    </h2>
  );
}
