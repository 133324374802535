'use client';

import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import '../../app/animations.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { slug } from '@/utils/functions';

export default function CardHome({ item }) {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Link
      className="max-w-[360px] rounded-lg shadow-lg transition duration-300 border-[1px] border-transparent hover:border-customColorGreen200"
      href={`/conteudo/${slug(item.fileName)}`}
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="500"
      aria-label={`Link para o conteúdo ${item.fileName}`}
    >
      <div className="w-full h-[220px] relative">
        <Image
          src={item.fileUrl}
          alt={item.fileName}
          fill
          quality={100}
          className="rounded-t-lg"
        />
      </div>
      <div className="p-4 flex flex-col gap-4 items-start justify-between bg-news-card h-[300px] rounded-b-lg">
        <h2 className="text-lg fhd:text-xl text-customColorWhite font-bold">
          {item.fileName}
        </h2>
        <h2 className="text-[14px] text-customColorWhite font-medium">
          {`${item.fileDescription.substring(0, 148)}...`}
        </h2>
        <div className="text-customColorGreen font-semibold text-[14px]">
          Ver mais
        </div>
      </div>
    </Link>
  );
}
