'use client';

import React from 'react';
import Card from './Card';
import Gradient from './Gradient';

export default function OutPlatforms({ ourPlatformsContent }) {
  const data = ourPlatformsContent;
  const items = data?.json_editor?.items;
  const images = data?.files;

  return (
    <div className="px-6 lg:px-14 2xl:px-24 fhd:px-52 py-10 lg:py-20 flex flex-col gap-10">
      <div className="w-full flex flex-row items-center gap-6">
        <div className="w-auto flex-shrink-0">
          <Gradient text={data.textarea} />
        </div>
        <div className="flex-grow h-[4px] bg-gradient-to-r from-customColorGreen200 to-customColorGreen500 rounded-s-lg" />
      </div>
      <div className="flex flex-col gap-8 2xl:gap-10 fhd:gap-14 py-10 !overflow-y-hidden">
        {items &&
          items.map((item, index) => (
            <Card key={index} item={item} image={images[index]} index={index} />
          ))}
      </div>
    </div>
  );
}
