'use client';

import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import GradientButton from '../GradientButton';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function HomeCompanies({ companiesContent }) {
  const data = companiesContent;
  const files = companiesContent.files;

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="w-full flex flex-col gap-20 items-center">
      <div className="w-full">
        <div
          className="[&>strong]:text-customColorGreen200 text-customColorWhite text-center font-medium text-[22px] 2xl:text-[28px] fhd:text-[32px]"
          dangerouslySetInnerHTML={{ __html: data.textarea }}
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="500"
        />
      </div>
      <div className="w-fit place-items-center grid grid-cols-3 lg:grid-cols-5 gap-4 xl:gap-24">
        {files &&
          files.map((file, index) => (
            <div
              key={index}
              className="w-28 h-16 relative group"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <Image
                src={file.fileUrl}
                alt={file.fileName}
                fill
                // className="object-contain grayscale invert-[.30] hover:invert-0 hover:brightness-100 group-hover:grayscale-0 hover:bg-customColorWhite hover:scale-[1.06] p-2 rounded-xl transition-all duration-300"
                className="object-contain grayscale invert brightness-100 hover:invert-0 hover:brightness-100 group-hover:grayscale-0 hover:bg-customColorWhite hover:scale-[1.06] p-2 rounded-xl transition-all duration-300"
              />
            </div>
          ))}
      </div>
      <div className="w-full text-center flex flex-col items-center gap-6">
        <h2 className="text-customColorWhite font-bold text-[20px] xl:text-[22px] 2xl:text-[24px]">
          {data.textarea2}
        </h2>
        <Link href={data.textarea4} aria-label={`Link para ${data.textarea3}`}>
          <GradientButton text={data.textarea3} />
        </Link>
      </div>
    </div>
  );
}
