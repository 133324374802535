import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/BigNumbers/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/HomeBanners/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/HomeCompanies/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/HomeCTA2/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/HomeSpinOff/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/NewsHome/Card.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/OurPlatforms/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/TestimonialsSection/Card.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/Accordion.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionContent"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/AccordionContent.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionPanel"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/AccordionPanel.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionTitle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/AccordionTitle.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Banner/BannerCollapseButton.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Carousel/Carousel.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["DarkThemeToggle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/DarkThemeToggle/DarkThemeToggle.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Datepicker"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Datepicker/Datepicker.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Drawer"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Drawer/Drawer.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["DrawerHeader"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Drawer/DrawerHeader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["DrawerItems"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Drawer/DrawerItems.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Dropdown"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Dropdown/Dropdown.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownDivider"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Dropdown/DropdownDivider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownHeader"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Dropdown/DropdownHeader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownItem"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Dropdown/DropdownItem.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Floating"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Floating/Floating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MegaMenu"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/MegaMenu/MegaMenu.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MegaMenuDropdown"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/MegaMenu/MegaMenuDropdown.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MegaMenuDropdownToggle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/MegaMenu/MegaMenuDropdownToggle.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Modal/Modal.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ModalBody"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Modal/ModalBody.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ModalFooter"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Modal/ModalFooter.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ModalHeader"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Modal/ModalHeader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Navbar/Navbar.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarBrand"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Navbar/NavbarBrand.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarCollapse"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Navbar/NavbarCollapse.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarLink"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Navbar/NavbarLink.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarToggle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Navbar/NavbarToggle.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Popover"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Popover/Popover.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Rating"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Rating/Rating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["RatingStar"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Rating/RatingStar.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/Sidebar.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarCollapse"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/SidebarCollapse.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarCTA"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/SidebarCTA.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItem"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/SidebarItem.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItemGroup"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/SidebarItemGroup.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItems"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/SidebarItems.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarLogo"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Sidebar/SidebarLogo.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Table/Table.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TableBody"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Table/TableBody.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TableCell"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Table/TableCell.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TableHead"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Table/TableHead.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TableHeadCell"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Table/TableHeadCell.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TableRow"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Table/TableRow.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TabItem"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Tabs/TabItem.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Tabs/Tabs.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Timeline"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/Timeline.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineBody"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/TimelineBody.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineContent"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/TimelineContent.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineItem"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/TimelineItem.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TimelinePoint"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/TimelinePoint.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineTime"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/TimelineTime.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineTitle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Timeline/TimelineTitle.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Toast"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Toast/Toast.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ToastToggle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Toast/ToastToggle.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useThemeMode"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/hooks/use-theme-mode.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeClientInit"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/theme-store/init/client.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeModeInit"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/theme-store/init/mode.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"components/GradientText/index.jsx\",\"import\":\"Oswald\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"oswald\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"components/OurPlatforms/Gradient.jsx\",\"import\":\"Chakra_Petch\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"chakra\"}");
