import React from 'react';
import Gradient from './Gradient';
import './animation.css';

export default function Card({ item }) {
  return (
    <div className="w-full flex flex-col items-center justify-between md:justify-start gap-2 px-4 py-6 border-[2px] border-customColorGreen200 box rounded-xl bg-custom-gradient min-h-[150px] md:min-h-full">
      <Gradient text={item.number} />
      <h3 className="text-white font-medium text-center">{item.description}</h3>
    </div>
  );
}
