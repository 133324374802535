'use client';

import React from 'react';
import { Pagination, EffectCreative, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';

import Image from 'next/image';
import { RatingUsers } from '../RatingUsers';

export default function CardCarousel({ items }) {
  return (
    <Swiper
      modules={[Pagination, EffectCreative, Autoplay]}
      slidesPerView={1}
      spaceBetween={30}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      grabCursor={true}
      autoplay={true}
      className="w-[98%] h-full"
    >
      {items &&
        items.map((item, index) => (
          <SwiperSlide key={index}>
            <Card item={item} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

function Card({ item }) {
  return (
    <div className="bg-custom-gradient border-[1px] border-customColorGreen200 rounded-xl flex flex-col gap-14 px-6 md:px-10 py-8 lg:py-10 justify-center h-full relative z-20">
      <div className="absolute top-4 left-4 z-10">
        <svg width="80" height="60" viewBox="0 0 86 67" fill="none">
          <path
            d="M48.5128 67V47.8885C48.5128 35.0011 51.6735 24.4568 57.9949 16.2557C64.3162 7.9082 73.6513 2.48962 86 0V16.4754C80.8547 17.647 76.5915 19.8437 73.2103 23.0656C69.9761 26.141 67.8444 29.8022 66.8154 34.0492H81.5897V67H48.5128ZM0 67V47.8885C0 35.0011 3.16068 24.4568 9.48205 16.2557C15.8034 7.9082 25.1385 2.48962 37.4872 0V16.4754C32.3419 17.647 28.0786 19.8437 24.6974 23.0656C21.4632 26.141 19.3316 29.8022 18.3026 34.0492H33.0769V67H0Z"
            fill="#1E1E1E"
          />
        </svg>
      </div>
      <div className="w-full text-start relative z-30">
        <h2 className="text-customColorWhite text-base font-medium">
          {item.fileDescription}
        </h2>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:items-center justify-start">
        <Image
          src={item.fileUrl}
          alt={item.fileName}
          width={80}
          height={80}
          className="rounded-full"
        />
        <div className="w-full h-full flex flex-col justify-around">
          <RatingUsers />
          <div>
            <h2 className="text-customColorWhite text-[14px] font-bold">
              {item.fileName}
            </h2>
            {item.fileTags.map((tag, index) => (
              <p
                className="text-customColorGrayTextCard text-[14px] font-medium"
                key={index}
              >
                {tag}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
