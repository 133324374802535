'use client';

import React, { useEffect } from 'react';
import GradientText from '../GradientText';
import Card from './Card';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function BigNumbers({ bigNumbersContent }) {
  useEffect(() => {
    Aos.init();
  }, []);

  const data = bigNumbersContent;
  const items = bigNumbersContent.json_editor.bigNumbers;

  return (
    <div className="w-full h-full px-10 md:px-14 xl:px-32 2xl:px-40 py-10 lg:py-20 2xl:py-28 flex flex-col gap-4 xl:gap-10 fhd:gap-14">
      <div className="w-full">
        <GradientText text={data.input} />
      </div>
      <div className="w-full fhd:px-48">
        <div
          className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          {items.map((item, index) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
