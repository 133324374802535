'use client';

import React, { useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import GradientButton from '../GradientButton';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function Card({ item, image, index }) {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="w-full rounded-lg bg-customColorBlack flex flex-col md:flex-row"
      data-aos={index === 0 ? 'fade-down' : 'fade-up'}
      data-aos-easing="linear"
      data-aos-duration="400"
    >
      <div
        className={`w-full md:w-[45%] h-[280px] sm:h-[300px] md:h-[380px] 2xl:h-[400px] fhd:h-[430px] relative ${
          index === 0
            ? 'md:order-1 border-l-2 border-customColorGreen200 rounded-t-xl md:rounded-t-none md:rounded-tl-xl md:rounded-bl-xl'
            : 'md:order-2 border-r-2 border-customColorGreen200 rounded-t-xl md:rounded-t-none md:rounded-tr-xl md:rounded-br-xl'
        }`}
      >
        <Image
          src={image.fileUrl}
          alt={image.fileName}
          fill
          quality={100}
          priority
          className={`object-cover ${
            index === 0
              ? 'rounded-t-xl md:rounded-t-none md:rounded-tl-xl md:rounded-bl-xl'
              : 'rounded-t-xl md:rounded-t-none md:rounded-tr-xl md:rounded-br-xl'
          }`}
        />
      </div>
      <div
        className={`w-full md:w-[55%] fhd:w-[65%] flex flex-col px-6 py-10 gap-10 md:gap-4 lg:gap-0 lg:py-4 2xl:px-14 2xl:py-8 md:justify-around ${
          index === 0 ? 'md:order-2' : 'md:order-1'
        }`}
      >
        <div className="flex flex-col gap-6 md:gap-2">
          <h2 className="text-[16px] fhd:text-[20px] tracking-[.25em] font-bold">
            <span className="text-customColorGreen">
              {item.title.split(' ')[0]}{' '}
            </span>{' '}
            <span className="text-white">
              {item.title.split(' ').slice(1).join(' ')}{' '}
            </span>
          </h2>
          <div
            className="text-customColorWhite text-[20px] xl:text-[22px] 2xl:text-[28px] fhd:text-[36px]"
            dangerouslySetInnerHTML={{ __html: item.subtitle }}
          />
        </div>
        <p className="text-customColorWhite">{item.description}</p>
        <Link
          href={item.link}
          className="w-fit"
          aria-label={`Link para ${item.button}`}
        >
          <GradientButton text={item.button} />
        </Link>
      </div>
    </div>
  );
}
