'use client';

import { Rating } from 'flowbite-react';

export function RatingUsers() {
  const customTheme = {
    root: {
      base: 'flex items-center',
    },
    star: {
      empty: 'text-gray-300 dark:text-gray-500',
      filled: 'text-yellow-400',
      sizes: {
        sm: 'h-4 w-4',
      },
    },
  };

  return (
    <Rating theme={customTheme} size="sm">
      <Rating.Star />
      <Rating.Star />
      <Rating.Star />
      <Rating.Star />
      <Rating.Star />
    </Rating>
  );
}
